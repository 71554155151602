import PropTypes from 'prop-types'
import React from 'react'

import style from './read-time.module.css'


const ReadTime = ({ time }) => (
  <div className={style.readTime}>
    {time}
  </div>
)
ReadTime.propTypes = {
  time: PropTypes.string.isRequired,
}

export default ReadTime
