import PropTypes from 'prop-types'
import React from 'react'
import RehypeReact from 'rehype-react'

import Heading from '../components/heading'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import Preformat from '../components/preformat'
import Table from '../components/table'
import TableCell from '../components/table-cell'
import TableHeading from '../components/table-heading'
import TableRow from '../components/table-row'

import style from './markdown.module.css'


const createComponent = (component, props) => {
  return React.createElement(component, props, props.children)
}
const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    a: Link,
    h1: Heading,
    h2: (props) => createComponent(Heading, {...props, level: 2}),
    h3: (props) => createComponent(Heading, {...props, level: 3}),
    h4: (props) => createComponent(Heading, {...props, level: 4}),
    h5: (props) => createComponent(Heading, {...props, level: 5}),
    h6: (props) => createComponent(Heading, {...props, level: 6}),
    li: ListItem,
    ol: (props) => createComponent(List, {...props, ordered: true}),
    p: Paragraph,
    pre: Preformat,
    table: Table,
    td: TableCell,
    th: TableHeading,
    tr: TableRow,
    ul: List,
  },
}).Compiler


/**
 *
 * @param {*} param0
 */
const Markdown = ({ tree }) => (
  <div className={style.markdown}>
    {renderAst(tree)}
  </div>
)

Markdown.propTypes = {
  tree: PropTypes.object.isRequired,
}

export default Markdown
