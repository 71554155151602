import PropTypes from 'prop-types'
import React from 'react'

import style from './table-cell.module.css'


const TableCell = ({children}) => (
  <td className={style.tableCell}>
    {children}
  </td>
)
TableCell.propTypes = {
  children: PropTypes.node,
}
export default TableCell
