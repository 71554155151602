import PropTypes from 'prop-types'
import React from 'react'

import style from './table-heading.module.css'


const TableHeading = ({ children }) => (
  <th className={style.tableHeading}>
    {children}
  </th>
)
TableHeading.propTypes = {
  children: PropTypes.node,
}
export default TableHeading
