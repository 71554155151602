import PropTypes from 'prop-types'
import React from 'react'

import style from './preformat.module.css'


const Preformat = ({ children }) => (
  <pre className={style.preformat}>
    {children}
  </pre>
)
Preformat.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Preformat
