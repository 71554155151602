import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import ReadTime from '../components/read-time'
import SEO from '../components/seo'


export const query = graphql`
  query MarkdownTemplateQuery($path: String!) {
    ...SiteInfo
    markdown: markdownRemark(fields: { slug: { eq: $path }}) {
      excerpt
      htmlAst
      frontmatter {
        title
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`

export default ({ data }) => {
  const { markdown } = data

  const page = markdown.frontmatter.title
  const keywords = page.split(/\W/)
  const trail = [
    {
      name: page,
      slug: markdown.fields.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: markdown.excerpt,
    keywords,
    name: page,
    slug: markdown.fields.slug,
  }

  return (
    <Layout>
      <SEO
        description={markdown.excerpt}
        keywords={keywords}
        schema={schema}
        title={page}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>
      <ReadTime time={markdown.fields.readingTime.text} />

      <Markdown tree={markdown.htmlAst} />

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
